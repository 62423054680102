<!-- @Author: Yu_Bo -->
<template>
	<div class='edit_version zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="800px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">编辑</div>
      </div>
      <div class="inv_main">
        <div class="main_text">
          <div class="left"><span>*</span>软件名称</div>
          <div class="right">
            <el-input v-model="name" placeholder="请输入软件名称" maxlength="10" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left"><span>*</span>版本标题</div>
          <div class="right">
            <el-input v-model="title" placeholder="请输入版本标题" maxlength="10" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left"><span>*</span>版本号</div>
          <div class="right">
            <el-input v-model="ver_code" placeholder="请输入版本号" maxlength="10" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left"><span>*</span>版本名称</div>
          <div class="right">
            <el-input v-model="ver_name" placeholder="请输入版本名称(例如:V1.0.0)" maxlength="10" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text" style="width: 100%;">
          <div class="left"><span>*</span>下载地址</div>
          <div class="right">
            <el-input v-model="download_url" placeholder="请输入下载地址">
            </el-input>
          </div>
        </div>
        <div class="main_text" style="width: 100%;">
          <div class="left"><span></span>强制更新</div>
          <div class="right" style="display: flex;align-items: center;">
            <el-switch v-model="renew_show" active-color="#2B4AFE"></el-switch>
          </div>
        </div>
        <div class="main_text" style="width: 100%;">
          <div class="left"><span>*</span>更新说明</div>
          <div class="right">
            <el-input type="textarea" :rows="6" resize='none' maxlength="50" show-word-limit placeholder="请输入更新说明"
            	v-model="notes">
            </el-input>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding: 0 20px;">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">提 交</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        dialogVisible:false,
        //
        id:'',
        name:'',
        title:'',
        ver_code:'',
        ver_name:'',
        download_url:'',
        renew_show:false,
        notes:'',
        //
        btnLoading:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(info) {
        if(info){
          this.id=info.id
          this.name=info.name
          this.title=info.title
          this.ver_code=info.version_code
          this.ver_name=info.version_name
          this.download_url=info.url
          this.renew_show=info.status==1?true:false
          this.notes=info.descr
        }
        this.dialogVisible = true
      },
      // 取消
      cancelBtn() {
        this.id=''
        this.name=''
        this.title=''
        this.ver_code=''
        this.ver_name=''
        this.download_url=''
        this.renew_show=false
        this.notes=''
        this.dialogVisible = false
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.name==''){
          that.$errMsg('请输入软件名称')
          return
        }else if(that.title==''){
          that.$errMsg('请输入版本标题')
          return
        }else if(that.ver_code==''){
          that.$errMsg('请输入版本号')
          return
        }else if(that.ver_name==''){
          that.$errMsg('版本名称')
          return
        }else if(that.download_url==''){
          that.$errMsg('请输入下载地址')
          return
        }else if(that.notes==''){
          that.$errMsg('请输入更新说明')
          return
        }else{
          // 添加
          that.btnLoading=true
          var params = {
            name:that.name,
            title:that.title,
            version_code:that.ver_code,
            version_name:that.ver_name,
            url:that.download_url,
            status:that.renew_show?1:2,
            descr:that.notes,
          }
          that.$setupApi.putVersion(params,that.id).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.edit_version{
    .inv_main {
      width: 100%;
      padding: 10px 20px 0 0;
      display: flex;
      flex-wrap: wrap;

      .main_text {
        width: 50%;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .left {
          width: 90px;
          line-height: 40px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          span {
            color: #E00C25;
          }
        }

        .right {
          width: calc(100% - 110px);

          .el-input {
            width: 100%;
          }

          .el-select {
            width: 100%;
          }
          .el-cascader{
            width: 100%;
          }
          .right_txt{
            padding-top: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF2B2B;
          }
        }
      }
      .main_text:last-child {
        margin-bottom: 0;
      }
    }
	}
</style>
