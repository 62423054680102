<!-- @Author: Yu_Bo -->
<template>
	<div class='version_control'>
    <!-- 标题 -->
    <div class="vue_title">版本设置</div>
    <!--  -->
    <div class="version_main">
      <div class="main_table">
        <el-table :data="list" v-loading="loading" style="width: 100%"
          header-cell-class-name="table-header" cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
          </template>
          <el-table-column prop="name" label="软件名称" width="150px" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column prop="title" label="版本标题" width="150px" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column prop="descr" label="更新说明" width="300px" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column prop="version_code" label="版本号" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column prop="version_name" label="版本名称" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column label="下载地址" align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="color: #2E4BF2;cursor: pointer;" @click="copyBtn(scope.row.version_name,scope.row.url,scope.row.id)">点击复制</span>
            </template>
          </el-table-column>
          <el-table-column label="是否强制更新" align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">是</span>
              <span style="color: #E00C25;" v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column prop="update_time" label="更新时间" align='center' show-overflow-tooltip></el-table-column>
          <el-table-column class-name="last_class" label="操作" fixed="right" width="100px" align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editBtn(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page_box" v-if="total" style="padding-bottom: 20px;">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- 编辑 -->
    <edit-version ref="version" @submit='getList'></edit-version>
	</div>
</template>

<script>
  import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
  import PagingPage from '@/components/paging_page/paging_page.vue'//分页
  import EditVersion from '../components/editVersion.vue'  //编辑弹窗
	export default {
		components: {EmptyData,PagingPage,EditVersion},
		props: {},
		data() {
			return{
        list:[],
        loading:false,
        //
        page: 1,
        limit: 10,
        total:0,
			}
		},
		computed: {},
		watch: {},
		created() {
      this.search()
    },
		mounted() {},
		methods: {
      search() {
      	this.page = 1
      	this.list = []
      	this.total = 0
      	this.getList()
      },
      // 列表
      getList(){
        var that = this
        that.loading=true
        var params = {
          page: that.page,
          limit: that.limit,
        }
        that.$setupApi.adminVersion(params).then(res => {
          that.loading = false
          if (res.code == 1000) {
            that.list=res.result?.data
            that.total=res.result?.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.getList()
      },
      // 复制
      copyBtn(name,val,id){
        var url = ''
        if(id==1){
          url = val+'/yc_digital_live-'+name+'.exe'
        }else{
          url = val+'/yc-live-'+name+'.exe'
        }
        this.$copyText(url).then((item) => {
              this.$succMsg('复制成功')
          })
      },
      // 编辑
      editBtn(info){
        this.$refs.version.openDialogBtn(info)
      },
    },
	}
</script>

<style lang='scss' scoped>
	.version_control{
    width: 100%;
    .version_main{
      width: 100%;
      padding: 20px 20px 0;
    }
	}
</style>
